import React from 'react'

export default function Show() {
    return (
        <div class="show1 container-fluid">            
            <div className="row" id="seatsL">
            <div className="listseats"><h1>The vacant seats</h1></div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <br/>
            <h1 class="aisle">Aisle</h1>
            <br/>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://localhost:3000/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://localhost:3000/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div><button class="proceed btn-large bg-success">Proceed now</button></div>
            </div>
    </div>
    )
}