import React from 'react'

export default function Footer() {
    return (
        <div>
        <footer className="foot bg-dark py-3">
            <p className="foot-talk text-center">Caringly Carafted By Show The Code</p>
            <p className="foot-talk text-center">Chidam | Arman | Luqmaan | Vishnu</p>
        </footer>
        </div>
    )
}
