import React from 'react'

export default function Show() {
    return (
        <div class="show1 container-fluid">            
            <div className="row" id="seatsL">
            <div className="listseats"><h1>The vacant seats</h1></div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <br/>
            <h1 class="aisle">Aisle</h1>
            <br/>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podag">
                    <a href="http://khana.vercel.app/showi" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <div className="col-lg-1">
                <div className="podar">
                    <a href="http://khana.vercel.app/showl" class="podaseat"><p>ㅤ</p></a>                    
                </div>            
            </div>
            <br/>
            <br/>
            <br/>
            <h1 class="cinfo">Images and info</h1>
            <div id="carouselExampleFade" class="carousel slide carousel-fade ms-auto me-auo" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img src="http://crispyfriedopinions.com/files/2019/12/SreeAkshayam_1.jpeg?v=1605712494" class="d-block w-100"/>
                    <div class="carousel-caption d-none d-md-block">
                        <h5>NAME : SREE AKSHYAM</h5>
                        <h5>TOTAL ⭐🌟 : 5.0/5.0</h5>
                        <h5>TOTAL SEATS : 48</h5>
                        <h5>TOTAL VACANCY : 30</h5>
                        <p>ADDRESS: 15th street , Anna nagar west extn , Chennai , 600040</p>
                    </div>
                    </div>
                    <div class="carousel-item">
                    <img src="https://theculinarytrail.files.wordpress.com/2015/10/img_20151017_222549_hdr.jpg" class="d-block w-100"/>
                    <div class="carousel-caption d-none d-md-block">
                        <h5>NAME : SREE AKSHYAM</h5>
                        <h5>TOTAL ⭐🌟 : 5.0/5.0</h5>
                        <h5>TOTAL SEATS : 48</h5>
                        <h5>TOTAL VACANCY : 30</h5>
                        <p>ADDRESS: 15th street , Anna nagar west extn , Chennai , 600040</p>
                    </div>
                    </div>
                    <div class="carousel-item">
                    <img src="https://pbs.twimg.com/media/CnTziD9VYAAkVBE.jpg" class="d-block w-100"/>
                    <div class="carousel-caption d-none d-md-block">
                        <h5>NAME : SREE AKSHYAM</h5>
                        <h5>TOTAL ⭐🌟 : 5.0/5.0</h5>
                        <h5>TOTAL SEATS : 48</h5>
                        <h5>TOTAL VACANCY : 30</h5>
                        <p>ADDRESS: 15th street , Anna nagar west extn , Chennai , 600040</p>
                    </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
                </div>
            </div>
    </div>
    )
}
